<template>
  <div>
    <b-alert
      v-if="promptStatus.alert_variant != 'Expired'"
      :show="promptStatus.dismissCountDown"
      dismissible
      :variant="promptStatus.alert_variant"
      @dismissed="promptStatus.dismissCountDown = 0"
    >
      <template>{{ promptStatus.alertText }}</template>
    </b-alert>
    <b-modal
      v-model="modalShow"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      scrollable
      title="Login Expired"
      id="expired"
      ref="modal"
      size="sm"
      >Login expired, please login again
      <template v-slot:modal-footer>
        <b-button size="sm" variant="primary" @click="sign()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'prompt',
  data: function() {
    return {
      modalShow: false
    };
  },
  components: {},
  props: {},
  methods: {
    init: function() {
      // 全局修改方式
    },
    sign: function() {
      window.location.href = 'https://www.tecrm.net/index.php?id=7';
    }
  },
  computed: {
    ...mapState({
      promptStatus: state => state.statusprompt.status
    }),
    ...mapGetters(['success'])
  },
  mounted() {
    this.init();
  },
  watch: {
    'promptStatus.alert_variant'(n) {
      console.log(n);
      if (n == 'Expired') {
        this.modalShow = true;
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 0,
          alert_variant: '',
          alertText: ''
        });
      }
    }
  }
};
</script>
<style scoped></style>
