<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobile></KTHeaderMobile>
    <div class="zhang"></div>
    <LoadingProgressBar></LoadingProgressBar>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <KTAside></KTAside>
      <div
        class="
          kt-grid__item kt-grid__item--fluid
          kt-grid kt-grid--hor
          kt-wrapper
        "
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>
        <Prompt></Prompt>
        <div
          class="
            kt-content
            kt-grid__item kt-grid__item--fluid
            kt-grid kt-grid--hor
          "
          id="kt_content"
        >
          <KTSubheader
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          ></KTSubheader>
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': 'fixed' }"
          >
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <KTFooter v-if="0"></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HtmlClass from '@/common/services/htmlclass';
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from '@/store/modules/htmlclass/actions';
import KTHeaderMobile from '@/views/theme/header/HeaderMobile.vue';
import KTHeader from '@/views/theme/header/Header';
import KTSubheader from '@/views/theme/subheader/Subheader';
import KTFooter from '@/views/theme/footer/Footer';
import KTAside from '@/views/theme/aside/Aside';
import KTScrollTop from '@/views/partials/layout/ScrollTop';
import Prompt from '@/components/Prompt/Index';
import LoadingProgressBar from '@/components/LoadingProgress/Index.vue';
import { SET_BREADCRUMB } from '@/store/modules/breadcrumbs';

export default {
  name: 'Base',
  components: {
    KTHeader,
    KTSubheader,
    KTAside,
    KTScrollTop,
    KTFooter,
    Prompt,
    LoadingProgressBar,
    KTHeaderMobile
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'kt-page--loading');
    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'kt-page--loading');
    }, 2000);

    // 绑定当前的breadcrumbs.
    this.onChangeRoute(this.$route);
  },
  methods: {
    onChangeRoute(to) {
      const breadcrumbs = [];
      const len = to.matched.length;
      to.matched.forEach((item, index) => {
        if (item.path !== '') {
          if (index === len - 1) {
            breadcrumbs.push({ title: this.$t(item.meta.i18n) });
          } else {
            breadcrumbs.push({
              title: this.$t(item.meta.i18n),
              route: item.path
            });
          }
        }
      });
      this.$store.dispatch(`breadcrumbs/${SET_BREADCRUMB}`, breadcrumbs);
    }
  },
  computed: {
    ...mapGetters('breadcrumbs', ['breadcrumbs', 'pageTitle']),
    ...mapGetters(['currentUser', 'layoutConfig'])
  },
  watch: {
    $route: 'onChangeRoute'
  }
};
</script>
<style>
#nprogress .bar {
  top: 46px;
}
#nprogress .spinner {
  top: 61px;
}
#nprogress .peg {
  display: none;
}
</style>
