<template>
  <b-progress
    id="loading-progress-bar"
    :value="value"
    :max="max"
    class="mb-3 hide"
  ></b-progress>
</template>
<script>
// import { mapState, mapGetters } from 'vuex';
// import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'LoadingProgress',
  data: function() {
    return {
      value: 33.333333333,
      max: 100
    };
  },
  components: {},
  props: {},
  methods: {
    init: function() {
      // 全局修改方式
    }
  },
  computed: {
    // ...mapState({
    //   promptStatus: state => state.statusprompt.status
    // }),
    // ...mapGetters(['success'])
  },
  mounted() {
    this.init();
  },
  watch: {
    // 'promptStatus.alert_variant'(n) {
    //   console.log(n);
    //   if (n == 'Expired') {
    //     this.modalShow = true;
    //     this.$store.dispatch(PROMPT_STATUS, {
    //       dismissCountDown: 0,
    //       alert_variant: '',
    //       alertText: ''
    //     });
    //   }
    // }
  }
};
</script>
<style scoped>
#loading-progress-bar {
  margin: 0 !important;
  z-index: 99;
  position: fixed;
  width: 100%;
  height: 3px;
  top: 46px;
}
</style>
